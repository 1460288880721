import React from 'react';
import { Route, Routes, Navigate  } from 'react-router-dom'
import AllMeetupsPage from './pages/AllMeetups';
import BuyingPage from './pages/Buying';
import NewBuyingPage from './pages/NewBuying';
import AllBuyingItemsPage from './pages/BuyingItems';
import NewBuyingItemPage from './pages/NewBuyingItem';
import Layout from './components/layout/Layout';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Signout from './pages/Signout';
import Home from './pages/Home';
import { useContext } from "react";
import { AuthContext } from "./store/AuthContext";

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

  const {currentUser} = useContext(AuthContext)

  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />;
  };
           
    return ( 
    <Layout>
      
      <Routes>
        <Route path="/signup" element={<Signup/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path='/' exact element={<RequireAuth><Home/></RequireAuth>} /> 
        <Route path='/buying' element={<RequireAuth><BuyingPage/></RequireAuth>} />
        <Route path='/buyingitems' element={<RequireAuth><AllBuyingItemsPage/></RequireAuth>} />
        <Route path='/dev' element={<RequireAuth><AllMeetupsPage/></RequireAuth>} />
        <Route path='/out' element={<RequireAuth><Signout/></RequireAuth>} />
      </Routes>

    </Layout>
  );
}

export default App;
