import UICBuyingItems from './UICBuyingItemsNew';
import classes from './UICBuyingItemsList.module.css';
import React from 'react';
import {format} from 'date-fns';

function UICBuyingItemsList(props){
  
  function buyingItemChangeHandler(buyingItemData){
    console.log("nach oben geben");
    props.onBuyingItemChange(buyingItemData);
  }
  
  return (<table className="contentList table  table-hover">
        <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Produkt</th>
          <th scope="col">Menge</th>
          <th scope="col">Check</th>
        </tr>
      </thead>
      <tbody>
        {props.buyingItems
          .sort( (a,b) =>  a.LAST_CHANGE > b.LAST_CHANGE ? 1 : -1)
          .map((buyingItem) => (
          <UICBuyingItems key={buyingItem.ID}
            id={buyingItem.ID}
            creationDay={buyingItem.CREATION_DAY ? format(new Date(buyingItem.CREATION_DAY), 'dd.MM.yyyy HH:mm:ss') : ""}
            product={buyingItem.PRODUCT}
            amount={buyingItem.AMOUNT}
            creator={buyingItem.CREATOR}
            master={buyingItem.REF_BUYING}
            lastChange={buyingItem.LAST_CHANGE ? format(new Date(buyingItem.LAST_CHANGE), 'dd.MM.yyyy HH:mm:ss') : ""}
            done={buyingItem.DONE}
            onBuyingItemChange={buyingItemChangeHandler}/>
        ))}
      </tbody>
    </table>   
        );
}

export default UICBuyingItemsList;