import React from 'react';
import CardBuying from "../ui/CardBuying";
import {format} from 'date-fns';


function UICBuying(props)
{
    const headerTime = props.creationDay ? format(new Date(props.creationDay), 'dd.MM.yyyy HH:mm:ss') : "";
    const headerText =  "ID: " + props.id + " @ " + headerTime;
    
    return <div>
        <CardBuying header= {headerText} title={props.description} content={props.creator} buyingId={props.id} />
    </div>
}

export default UICBuying;