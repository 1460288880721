import { createContext, useState } from 'react';
import React from 'react';

const BuyingContext = createContext({
  buying: [],
  buyingTotal: 0,
  addBuying: (selectedBuying) => {},
  removeBuying: (buyingId) => {}
  //itemIsFavorite: (meetupId) => {}
});

export function BuyingContextProvider(props) {
  const [selectedBuying, setSelectedBuying] = useState([]);

  function addBuyingHandler(selectedBuying) {
    setSelectedBuying((prevSelectedBuying) => {
      console.log("Anzahl PSB: "+prevSelectedBuying.length);
      console.log(selectedBuying.id);      

      return prevSelectedBuying.concat(selectedBuying);
    });
  }

  function removeBuyingHandler(buyingId) {
    setSelectedBuying(prevSelectedBuying => {
      return prevSelectedBuying.filter(buying => buying.id !== buyingId);
    });
  }

  //function itemIsFavoriteHandler(meetupId) {
    //return userFavorites.some(meetup => meetup.id === meetupId);
  //}

  const context = {
    buying: selectedBuying,
    addBuying: addBuyingHandler,
    removeBuying: removeBuyingHandler
    //itemIsFavorite: itemIsFavoriteHandler
  };

  return (
    <BuyingContext.Provider value={context}>
      {props.children}
    </BuyingContext.Provider>
  );
}

export default BuyingContext;