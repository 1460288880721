import { useContext, useEffect, useState } from 'react';

import Card from 'react-bootstrap/Card';
import React from 'react';
import { Link } from 'react-router-dom';
import FavoritesContext from '../../store/favorites-context';
import BuyingContext from '../../store/buying-context';
import classes from './CardBuying.module.css';
import {format,parse } from 'date-fns';

function CardBuying(props) {
  
  const [list, setList] = useState([])

    const clickHandler = (e, buying) => {
      
      console.log("CHECKED "+e.target.checked);  
      
      const changedB = 
        {
          ID: buying.buyingId,
          CREATION_DAY: null,
          DESCRIPTION: buying.title,
          CREATOR: buying.content,
          BUYING_STATUS: e.target.checked ? 1 : 0
        };

        setList(changedB); 
    }

    useEffect(() => {
      // Perform whatever you want to do when list changes here
      const buyingData = list;
      const currentBuyingItem = buyingData.ID;
      const currentBuying = buyingData.REF_BUYING;
      
      if(buyingData.ID)
      {
      fetch(
          'https://seka.wittig-dresden.de/buying/'+buyingData.ID,
          {
            method: 'PATCH',
            body: JSON.stringify(buyingData),
            headers: {'Authorization': 'Basic '+btoa(process.env.REACT_APP_BASIC_AUTH),
                        'Access-Control-Request-Headers': 'Authorization',
                         //'Content-Type': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                      }
          })
          .then((response) => {
            return response.json();
          })
          .then((data) => {            
              console.log("Buying Status updated - reloaded next time");          
          });
        }

    }, [list])
  
  
  const favoriteCtx = useContext(FavoritesContext);
  const buyingCtx = useContext(BuyingContext);

  const itemIsFavorite = favoriteCtx.itemIsFavorite(props.buyingId);

  function toggleFavoriteStatusHandler(){
    if (itemIsFavorite) {
      favoriteCtx.removeFavorite(props.buyingId);
    } else
    {
      favoriteCtx.addFavorite({
        id: props.buyingId,
        creationDay: props.header,
        description: props.content,
        creator: props.title,
      });
    }
  }

  function toggleBuyingStatusHandler(){
    buyingCtx.addBuying({
        id: props.buyingId,
        creationDay: props.header,
        description: props.title,
        creator: props.content,
      });
    
  }
  /*<Button variant="primary" onClick={toggleFavoriteStatusHandler}>{itemIsFavorite ? 'BadgeDown' : 'BadgeUp'}</Button>*/
  /*<Link to='/buyingitems' onClick={toggleBuyingStatusHandler}><Button variant="primary" >Show BuyingItems</Button></Link>*/
  return (
      <Card border="Secondary" style={{ width: '20rem', margin: '1em' }} className={classes.cardLink}>
      <Card.Header>          
          <input className="form-check-input" 
                           type="checkbox" 
                           defaultChecked={props.done == 1 ? true : false}  
                           id="flexCheckDefault" 
                           onClick={(e) => clickHandler(e, props)}/>
          &nbsp;
          {props.header}
      </Card.Header>
      <Link  to='/buyingitems' onClick={toggleBuyingStatusHandler}>
      <Card.Body className={classes.cardLinkBody}>        
          <Card.Title>{props.title}</Card.Title>
          <Card.Text>({props.content})</Card.Text>  
      </Card.Body>
      </Link>
      </Card>

  );
}

export default CardBuying;