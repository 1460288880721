import { useState, useEffect } from 'react';
import React from 'react';
import UICBuyingList from "../components/buying/UICBuyingList";
import NewBuyingPage from './NewBuying';
import AppUser from "./AppUser";

function AllBuyingPage(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [loadedBuyings, setLoadedBuyings] = useState([]);
  
  
  function addNewBuyingHandler(buyingData){
    console.log("triggert addNewBuyingHandler ..");
    setLoadedBuyings(loadedBuyings.concat(buyingData));
  }

  useEffect(() => {
    document.title = 'sekapp - (sw) [2023]';
    setIsLoading(true);
    fetch(
      'https://seka.wittig-dresden.de/buying/',
      {
        method: 'GET',
        headers: {'Authorization': 'Basic '+btoa(process.env.REACT_APP_BASIC_AUTH),
                  'Access-Control-Request-Headers': 'Authorization',
                   //'Content-Type': 'application/json',
                  'Content-Type': 'application/x-www-form-urlencoded'
                 }
      })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const buyings = [];

        for (const key in data) {
          const buying = {
            ID: key,
            ...data[key]
          };
          //console.log(buying);
          buyings.push(buying);
        }

        setIsLoading(false);
        setLoadedBuyings(buyings);
      })
      .catch(error => {console.log(error);  throw(error);} );
  }, []);

  if (isLoading) {
    return (
      <section>
        <p>Retrieving Data ... Delivery soon...</p>
      </section>
    );
  }

  return <section>
            <h1>Einkaufszettel</h1>
            <NewBuyingPage onNewBuying={addNewBuyingHandler} />
            <br />
            <UICBuyingList buyings = {loadedBuyings} />
            <h2>Hier nochmal die Ausgabe des Arrays direkt:</h2>
            <h3><AppUser content="User: " /></h3>
            <ul>
            {loadedBuyings.map((buying) => {
                return <li key={buying.ID}>{buying.DESCRIPTION}</li>;
            })}
            </ul>
            </section>
}

export default AllBuyingPage;