import { useNavigate } from 'react-router-dom';
import React from 'react';
import UICNewBuyingItemForm from '../components/buying/UICNewBuyingItemForm';

function NewBuyingItemPage(props) {
    const history = useNavigate();

    /*function addBuyingItemHandler(buyingItemData){
        console.log(buyingItemData);
        fetch('https://seka.wittig-dresden.de/create/buyingitem',
        {
            method: 'POST',
            body: JSON.stringify(buyingItemData),
            headers: {'Authorization': 'Basic '+btoa(process.env.REACT_APP_BASIC_AUTH),
                      'Access-Control-Request-Headers': 'Authorization',
                       //'Content-Type': 'application/json',
                      'Content-Type': 'application/x-www-form-urlencoded'
                     }
        }).then(() => {
            history('/buying');
        });*/

        function addBuyingItemHandler(buyingItemData){
            console.log(buyingItemData);
            fetch('https://seka.wittig-dresden.de/create/buyingitem',
            {
                method: 'POST',
                body: JSON.stringify(buyingItemData),
                headers: {'Authorization': 'Basic '+btoa(process.env.REACT_APP_BASIC_AUTH),
                          'Access-Control-Request-Headers': 'Authorization',
                           //'Content-Type': 'application/json',
                          'Content-Type': 'application/x-www-form-urlencoded'
                         }
            }).then((response) => {
                return response.json();
            }).then((responseData) => {
                const insertedBuyingItmes = [];

                for (const key in responseData) {
                const insertedBuyingItem = {
                    ID: key,
                    ...responseData[key]
                };
                insertedBuyingItmes.push(insertedBuyingItem);
                }
                
                props.onNewBuyingItem(insertedBuyingItmes);

                //history('/buying');
            });



    }
    
    return (
        <section>
        <UICNewBuyingItemForm onAddBuyingItem={addBuyingItemHandler}/>
        </section>
        );
        

}

export default NewBuyingItemPage;