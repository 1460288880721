import {useEffect, useState, useRef} from 'react';
import React from 'react';
//Unsicher ob ich überhaupt diesen State brauche und nicht gleich auf den Storage gehe
const AppUser = (props) => {
    const [userName, setUserName] = useState([]); useEffect(()=>{
      const uuid = JSON.parse(localStorage.getItem("user")) || null;
      const storedUserName = JSON.parse(localStorage.getItem("userName")) || '...';
      if(storedUserName === '...' && uuid != null)
      {
          fetch(
              'https://seka.wittig-dresden.de/uuid/'+uuid.uid,
              {
                method: 'GET',
                headers: {'Authorization': 'Basic '+btoa(process.env.REACT_APP_BASIC_AUTH),
                          'Access-Control-Request-Headers': 'Authorization',
                          //'Content-Type': 'application/json',
                          'Content-Type': 'application/x-www-form-urlencoded'
                        }
              })
              .then((response) => {
                return response.json();
              })
              .then((data) => {
                const storableUserData = {
                  ID : data.ID,
                  USER_NAME : data.USER_NAME,
                };                                
                setUserName(storableUserData);
                localStorage.setItem("userName", JSON.stringify(storableUserData));
                console.log(storableUserData);
              });
      }
      setUserName(storedUserName);
    },[]); 
    return (<span key={userName.ID}>{props.content} ( {userName.USER_NAME} )</span>);
     }

    export default AppUser;