import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import React from 'react';

import './index.css';
import App from './App';

import { FavoritesContextProvider } from "./store/favorites-context";
import { BuyingContextProvider } from "./store/buying-context";
import { AuthContextProvider } from "./store/AuthContext";





const container = document.getElementById('root');

// Create a root.
const root = ReactDOM.createRoot(container);


root.render( <React.StrictMode>
    <AuthContextProvider>
                <FavoritesContextProvider>
                    <BuyingContextProvider>
                         <BrowserRouter>
                                <App />
                            </BrowserRouter>
                    </BuyingContextProvider>
                </FavoritesContextProvider>
                </AuthContextProvider>
              </React.StrictMode>);


 