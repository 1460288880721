import { useState, useEffect, useContext } from 'react';
import React from 'react';
import UICBuyingItemsList from "../components/buying/UICBuyingItemsList";
import BuyingContext from '../store/buying-context';
import NewBuyingItemPage from './NewBuyingItem';
import classes from './BuyingItem.module.css';

function AllBuyingItemsPage(props) {
  const buyingCtx = useContext(BuyingContext);
  
  //console.log("CTX current Buying:" + buyingCtx.buying.slice(-1).map((buying) => buying.id));
  const currentBuyingIdToRetrieve = buyingCtx.buying.slice(-1).map((buying) => buying.id);
  const currentBuyingNameToRetrieve = buyingCtx.buying.slice(-1).map((buying) => buying.description);

  //BuyingItems Zeug
  const [isLoading, setIsLoading] = useState(true);
  const [loadedBuyingItems, setLoadedBuyingItems] = useState([]);
  const [error, setError] = useState(null);
  const [sop, setSOP] = useState(0);
    
  function buyingItemChangeHandler(buyingItemData){
    console.log("triggert von unten mit geänderten BI:", buyingItemData.ID);
    // Erst den alten Eintrag rausnehmen und dann neu hinzufügen
    // Sortiert wird nach Änderungen in UICBuyingItemsList
    setLoadedBuyingItems(loadedBuyingItems.filter(item => item.ID !== parseInt(buyingItemData.ID)));
    setLoadedBuyingItems(loadedBuyingItems => [buyingItemData, ...loadedBuyingItems]);

    //console.log(buyingItemData.ID + "DONE?" + buyingItemData.DONE);
      if(buyingItemData.DONE)
        setSOP(sop-1);
      else
        setSOP(sop+1);   

  }

  function addNewBuyingItemHandler(buyingItemData){
    console.log("triggert addNewBuyingItemHandler ..");
    setLoadedBuyingItems(loadedBuyingItems.concat(buyingItemData));

    setSOP(sop +1);
  }
  
  useEffect(() => {
    setIsLoading(true);
    fetch(
      'https://seka.wittig-dresden.de/buying/'+currentBuyingIdToRetrieve+'/buyingitems/',
      {
        method: 'GET',
        headers: {'Authorization': 'Basic '+btoa(process.env.REACT_APP_BASIC_AUTH),
                  'Access-Control-Request-Headers': 'Authorization',
                   //'Content-Type': 'application/json',
                  'Content-Type': 'application/x-www-form-urlencoded'
                 }
      })
      .then((response) => {
        if (!response.ok) {
          throw(response.status + ' Liste ist noch leer.'); 
        }          
        return response.json();
      })
      .then((data) => {
        setError(false);
        const buyingItmes = [];
        setSOP(0);

        for (const key in data) {
          const buyingItem = {
            ID: key,
            ...data[key]
          };
          buyingItmes.push(buyingItem);

          //console.log("Lade BI:"+buyingItem.ID+" "+buyingItem.DONE);
          if(!buyingItem.DONE)
            setSOP(prevSOP => prevSOP + 1);
        }

        setIsLoading(false);
        setLoadedBuyingItems(buyingItmes);
      })
      .catch(error => {
        console.log(error);
        setError(error);
        setIsLoading(false);})
  }, []);

  if (isLoading) {
    return (
      <section>
        <p>Retrieving Data ... Delivery soon...</p>
      </section>
    );
  }

  return <section>
            <h1>{currentBuyingNameToRetrieve} <span className={classes.badge}>{sop}</span> </h1>
            
            <NewBuyingItemPage onNewBuyingItem={addNewBuyingItemHandler}/>
            <br />
            <br />
            <UICBuyingItemsList buyingItems = {loadedBuyingItems} onBuyingItemChange={buyingItemChangeHandler}/>
          </section>
}

export default AllBuyingItemsPage;