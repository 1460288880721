import classes from './UICBuyingItems.module.css';
import React from 'react';
import { useState, useEffect} from 'react';
import {format,parse } from 'date-fns';
import Form from 'react-bootstrap/Form';

/*Alternative: bei OnClick wird direct fetch gemacht und nicht erst eine Liste geschrieben und bei onChange der Liste gefetcht
https://stackoverflow.com/questions/59097560/react-update-fetch-on-checkbox-click
*/

function UICBuyingItems(props)
{
    const [list, setList] = useState([])
    const [infoBoxHide, setInfoBoxHide] = useState(true);


    const clickTableEntryHandler = (e, buyingItem) => {
      //Nur auf-/zu-klappen, wenn vorher nicht die Checkbox geklickt wurde
      setInfoBoxHide(!infoBoxHide);
            
  }

    const clickHandler = (e, buyingItem) => {
        const changedBI = 
        {
          ID: buyingItem.id,
          //format(new Date(buyingItem.CREATION_DAY), 'dd.MM.yyyy HH:mm:ss')
          CREATION_DAY: format(new Date(parse(buyingItem.creationDay, 'dd.MM.yyyy HH:mm:ss', new Date())), 'yyyy-MM-dd HH:mm:ss'),
          CREATOR: buyingItem.creator,
          PRODUCT: buyingItem.product,
          REF_BUYING: buyingItem.master,
          LAST_CHANGE: format(new Date(parse(buyingItem.lastChange, 'dd.MM.yyyy HH:mm:ss', new Date())), 'yyyy-MM-dd HH:mm:ss'),
          AMOUNT: buyingItem.amount,
          DONE: e.target.checked ? 1 : 0
        };
      
        //console.log("CHECKED "+e.target.checked);
            
        setList(changedBI);  

        
    }

    useEffect(() => {
        // Perform whatever you want to do when list changes here
        const buyingData = list;
        const currentBuyingItem = buyingData.ID;
        const currentBuying = buyingData.REF_BUYING;

        console.log("ClickEffent-Selected BI: "+buyingData.ID);

        if(buyingData.ID)
        {
        fetch(
            'https://seka.wittig-dresden.de/buying/'+currentBuying+'/buyingitems/'+currentBuyingItem,
            {
              method: 'PATCH',
              body: JSON.stringify(buyingData),
              headers: {'Authorization': 'Basic '+btoa(process.env.REACT_APP_BASIC_AUTH),
                          'Access-Control-Request-Headers': 'Authorization',
                           //'Content-Type': 'application/json',
                          'Content-Type': 'application/x-www-form-urlencoded',
                        }
            })
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              const buyingItmes = [];
      
              for (const key in data) {
                const buyingItem = {
                  ID: key,
                  ...data[key]
                };
                console.log("After Click-Event Updated BI:");
                console.log('Returned Value:'+buyingItem.ID);
                console.log("nach oben geben");
                props.onBuyingItemChange(buyingItem);
                buyingItmes.push(buyingItem);

              }
      
              //setIsLoading(false);
              //setLoadedBuyingItems(buyingItmes);

              
            });
          }

    }, [list])

    
    return  <><tr>
                <th scope="row" className={classes.id}>{props.id}</th>
                <td onClick={(e) => clickTableEntryHandler(e, props)}>{props.product}</td>
                <td className={classes.amount}>{props.amount}</td>
                <td> <Form.Check
                             type="switch" 
                             id="flexCheckDefault"
                             defaultChecked={props.done == 1 ? true : false} 
                             onClick={(e) => clickHandler(e, props)}/>    
                </td>                
            </tr>
            <tr className={infoBoxHide ? classes.infoBoxHide : ''}><td className={classes.creationAndChangeDates} colspan="4"><b>{props.creator} </b>| Erstellt: {props.creationDay} | Geändert: {props.lastChange}</td></tr>         
            </>
}

export default UICBuyingItems;