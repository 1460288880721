import { Link } from 'react-router-dom';
import React from 'react';
import AppUser from "../../pages/AppUser";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import  { useState } from 'react';

function MainNavigation() {

  const [expanded, setExpanded] = useState(false);

  const handleNavItemClick = () => {
    // Schließe das Navbar.Collapse-Element, indem der Zustand auf false gesetzt wird
    setExpanded(false);
  };

  return (
    <header>
          <Navbar expanded={expanded} collapseOnSelect expand="lg" bg="warning"  >
            <Container>
              <Navbar.Brand>Einkaufs-App</Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)}/>
              <Navbar.Collapse id="responsive-navbar-nav" >
                <Nav className="me-auto">
                  <Link className="nav-link" style={{color: '#000'}} onClick={handleNavItemClick} to='/buying'>Zettel</Link>
                  <Link className="nav-link" style={{color: '#000'}} onClick={handleNavItemClick} to='/dev'>Brett</Link>
                  <Link className="nav-link" style={{color: '#000'}} onClick={handleNavItemClick} to='/out'><AppUser content="X"/></Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
    </header>
  );
}

export default MainNavigation;