import Card from 'react-bootstrap/Card';
import React from 'react';

function CardWithHeaderAndFooter(props) {
  return (
    <Card className="text-center">
      <Card.Header>{props.header}</Card.Header>
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text>
         {props.content}
        </Card.Text>
      </Card.Body>
      <Card.Footer className="text-muted">{props.footer}</Card.Footer>
    </Card>
  );
}

export default CardWithHeaderAndFooter;