import { useNavigate } from 'react-router-dom';
import React from 'react';
import NewBuyingForm from '../components/buying/NewBuyingForm';

function NewBuyingPage(props) {
    const history = useNavigate();

    /*function addBuyingHandler(buyingData){
       
        
        fetch('https://seka.wittig-dresden.de/buying',
        {
            method: 'POST',
            body: JSON.stringify(buyingData),
            headers: {'Authorization': 'Basic '+btoa('5:karl'),
                      'Access-Control-Request-Headers': 'Authorization',
                       //'Content-Type': 'application/json',
                      'Content-Type': 'application/x-www-form-urlencoded'
                     }
        }).then(() => {
            history('/');
        });

    }*/

    function addBuyingHandler(buyingData){
        console.log(buyingData);
        fetch('https://seka.wittig-dresden.de/buying',
        {
            method: 'POST',
            body: JSON.stringify(buyingData),
            headers: {'Authorization': 'Basic '+btoa(process.env.REACT_APP_BASIC_AUTH),
                      'Access-Control-Request-Headers': 'Authorization',
                       //'Content-Type': 'application/json',
                      'Content-Type': 'application/x-www-form-urlencoded'
                     }
        }).then((response) => {
            return response.json();
        }).then((responseData) => {
            const insertedBuyings = [];

            for (const key in responseData) {
            const insertedBuying = {
                ID: key,
                ...responseData[key]
            };
            insertedBuyings.push(insertedBuying);
            }
            
            props.onNewBuying(insertedBuyings);

            //history('/buying');
        });
    }

    return (
        <section>
        <NewBuyingForm onAddBuying={addBuyingHandler}/>
        </section>
        );
        

}

export default NewBuyingPage;