import * as React from "react";
import CardPP from "../components/ui/CardWithHeaderAndFooter";
import {format,  formatDistance} from 'date-fns';
import AppUser from "./AppUser";
import Form from 'react-bootstrap/Form';

const buildTime = new Date('2024-02-29 20:34');
const buildVersion = "1.4.300.";

const DUMMY_DATA = [
    {
      id: '2',
      title: 'Aktualisieren bei Änderungen (BI)',
      status: 'done'
    },
    {
      id: '3',
      title: 'UserName.js überlegen ob es nicht besser geht',
      status: 'done'
    },
    {
      id: '4',
      title: 'InfoBox, schließen automatisch oder wenn andere aufgeht?',
      status: 'in progress'
    },
    {
      id: '5',
      title: 'Löschen von Einträgen (BI)',
      status: 'in progress'
    },
    {
      id: '6',
      title: 'Farbänderung bei CheckBox (.form-check-input:checked background-color #fd510d border-color: #0d6efd',
      status: 'in progress'
    },
    {
      id: '7',
      title: 'Logout auf Server Page bring fehler, dass nix da ist => alles abfangen und auf Einstieg umschieben!?',
      status: 'in progress'
    },
    {
      id: '7',
      title: 'function buyingItemChangeHandler(buyingItemData)',
      status: 'done'
    }
  ];

function DevBoardPage() {
     const uuid = JSON.parse(localStorage.getItem("user")) || null;
     return <section>
            <h1>Dev Board - Next Steps</h1>
            <h3>build: {buildVersion+format(buildTime, 'yyyyMMdd')}</h3>
            {
              <CardPP header="next big thing"
              title=".-." 
              content={<ul>
              {DUMMY_DATA.map((meetup) => {
                  return meetup.status !== 'done' ? <li key={meetup.id}>{meetup.title}</li> : '' ;
              })}
              </ul>} 
              footer={formatDistance(buildTime, new Date(), { addSuffix: true })}/>
            }
             <Form.Check type="switch" id="custom-switch" label="Check this switch"/>    
            <p><AppUser uuid={uuid.uid}/></p>
            </section>
            
}

export default DevBoardPage;