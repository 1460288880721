import {useRef} from 'react';
import React from 'react';
import Card from '../ui/Card';
import classes from './NewBuyingForm.module.css';
import Button from 'react-bootstrap/Button';

function NewBuyingForm (props)
{
    const storedUserName = JSON.parse(localStorage.getItem("userName")) || '...';
    const descriptionInputRef = useRef();
    const creatorInputRef = useRef();
    const creationDayInputRef = useRef();

    function submitHandler(event){
        event.preventDefault();

        const enteredDescription = descriptionInputRef.current.value;
        const enteredCreator = creatorInputRef.current.value;
        const enteredCreationDay = creationDayInputRef.current.value;


        const buyingData = {
            creation_day : enteredCreationDay,
            creator : enteredCreator,
            description : enteredDescription,
        };

        console.log(buyingData);
        
        props.onAddBuying(buyingData);
    }

    return (
        
<Card>
        <form className={classes.form}>
            <div className={classes.control}>
            <label htmlFor='description'>Name</label>
            <input type='text' required id='description' ref={descriptionInputRef}/>
            </div>
            <div className={classes.control}>
            <input type='hidden' required id='creator' ref={creatorInputRef} value={storedUserName.USER_NAME}/>
            </div>
            <div className={classes.control}>
            <input type='hidden' id='creation_day' ref={creationDayInputRef}/>
            </div>
            <div className={classes.actions}>
                <Button variant="custom" onClick={submitHandler}>Zettel schreiben</Button>
            </div>
        </form>
     </Card>
    );

}

export default NewBuyingForm;