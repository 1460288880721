import UICBuying from './UICBuying';
import classes from './UICBuyingList.module.css';
import React from 'react';
function UICBuyingList(props){
       
    return (
     <div className={classes.box}>
        {props.buyings.sort( (a,b) => a.ID < b.ID ? 1 : -1)
                      .map((buying) => ( 
                        <UICBuying key={buying.ID} 
                                    id={buying.ID} 
                                    creationDay={buying.CREATION_DAY} 
                                    description={buying.DESCRIPTION} 
                                    creator={buying.CREATOR} 
                                />
                ))}
        </div>            
        );
}

export default UICBuyingList;