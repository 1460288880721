import React, {useState} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {  createUserWithEmailAndPassword  } from 'firebase/auth';
import { auth } from '../firebase';
import classes from './Login.module.css';
import Card from '../components/ui/Card';
 
const Signup = () => {
    const navigate = useNavigate();
 
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
 
    const onSubmit = async (e) => {
      e.preventDefault()
     
      await createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            console.log(user);
            navigate("/login")
            // ...
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            // ..
        });
 
   
    }
 
  return (
    <>
    <p> Create a new Account </p>    
    <Card>                                                                           
                    <form className={classes.form}>                                              
                <div className={classes.control}> 
                            <label htmlFor="email-address">
                                Email address
                            </label>
                            </div>                                             
                <div className={classes.control}> 
                            <input
                                type="email"
                                label="Email address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}  
                                required                                    
                                placeholder="Email address"                                
                            />
                        </div>
                                           
                <div className={classes.control}> 
                            <label htmlFor="password">
                                Password
                            </label>
                            </div>
                <div className={classes.control}> 
                            <input
                                type="password"
                                label="Create password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)} 
                                required                                 
                                placeholder="Password"/>
                        </div>   

                         <div className={classes.actions}>
                                <button onClick={onSubmit}>Sign up</button>
                            </div>                                                 
                    </form>                   
                    <p className="text-sm text-black text-center">
                        Already have an account?{' '}
                        <NavLink to="/login" >
                            Login
                        </NavLink>
                    </p>                   
</Card>
</>
  )
}
 
export default Signup