import {useContext, useState} from 'react';
import {  signInWithEmailAndPassword   } from 'firebase/auth';
import { auth } from '../firebase';
import { NavLink, useNavigate } from 'react-router-dom';
import classes from './Login.module.css';
import Card from '../components/ui/Card';

import {AuthContext} from "../store/AuthContext"
 
const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    
    const [error, setError] = useState(false);
    const {dispatch} = useContext(AuthContext);
       
    const onLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            dispatch({type:"LOGIN", payload:user})
            
            //Frage UserName in DB ab, speichern und weiter           
                        
            navigate("/")
            console.log(user);
        })
        .catch((error) => {
            setError(true);
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
       
    }
 
    return(
        <>  
            <p> Please enter your Credentials </p>    
            <Card>      
              <form className={classes.form}>                                              
                <div className={classes.control}>  
                    <label htmlFor='email-address'>eMail</label>
                </div>
                 
                <div className={classes.control}>
                    <input id="email-address"
                                    name="email"
                                    type="email"                                    
                                    required                                                                                
                                    placeholder="Email address"
                                    onChange={(e)=>setEmail(e.target.value)}
                                />
                </div>

                <div className={classes.control}>
                                <label htmlFor="password">
                                    Password
                                </label>
                                <input
                                    id="password"
                                    name="password"
                                    type="password"                                    
                                    required                                                                                
                                    placeholder="Password"
                                    onChange={(e)=>setPassword(e.target.value)}
                                />
                            </div>

                            <div className={classes.actions}>
                                <button onClick={onLogin}>Login</button>
                            </div>   
                            <span>{error && <span>Wrong email or password!</span>}</span>                        
                    </form>
                       
                        <p className="text-sm text-black text-center">
                            No account yet? {' '}
                            <NavLink to="/signup">Sign up</NavLink>
                        </p>
                                                   
            </Card>
        </>
    )
}
 
export default Login