import {useRef, useContext, useState} from 'react';
import React from 'react';
import Card from '../ui/Card';
import classes from './UICNewBuyingItemForm.module.css';
import BuyingContext from '../../store/buying-context';
import Button from 'react-bootstrap/Button';

function NewBuyingItemForm (props)
{
    const buyingCtx = useContext(BuyingContext);
    console.log("CTX current Buying:" + buyingCtx.buying.slice(-1).map((buying) => buying.id));
    const currentBuyingToRetrieve = buyingCtx.buying.slice(-1).map((buying) => buying.id);

    const productInputRef = useRef();
    const creatorInputRef = useRef();
    const creationDayInputRef = useRef();
    const masterInputRef = useRef();
    const amountInputRef = useRef();
    const storedUserName = JSON.parse(localStorage.getItem("userName")) || '...';
    
    const [error, setError] = useState(false);

    function submitHandler(event){
        event.preventDefault();

        const enteredProduct = productInputRef.current.value;
        const enteredCreator = creatorInputRef.current.value;
        const enteredCreationDay = creationDayInputRef.current.value;
        const enteredMaster = masterInputRef.current.value;
        const enteredAmount = amountInputRef.current.value == "" ? 1 : amountInputRef.current.value;

        const buyingItemData = {
            creation_day : enteredCreationDay,
            creator : enteredCreator,
            product : enteredProduct,
            buying   : enteredMaster,
            amount  : enteredAmount,
        };


        console.log('NewBuyingItemForm ',buyingItemData);
        
        try{
            props.onAddBuyingItem(buyingItemData);
        }catch(err)
        {   
            //Komme hier aktuell nicht hin, da das Fetch keinen Error wirft
            console.log(err);
            setError(true);
        }
        finally{

            if(!error)
            {
                productInputRef.current.value = ""; 
                amountInputRef.current.value = "";
            }
            
            setError(false);

        }
        
    }

    return (
     <Card>
        <form className={classes.form}>
            <div className={classes.control}>
            <label htmlFor='product'>Produkt</label>
            <input type='text' required id='product' ref={productInputRef}/>
            </div>
            
            <table className={classes.controlTable}>
                <tr>
                    <td>
                    <div className={classes.controlShort}>
                        <label htmlFor='amount'>Menge</label>
                        <input type='text' id='amount' ref={amountInputRef} />
                    </div>
                    <div className={classes.control}>
                        <input type='hidden' required id='creator' ref={creatorInputRef} value={storedUserName.USER_NAME} readOnly/>
                        <input type='hidden' id='creation_day' ref={creationDayInputRef}/>
                        <input type='hidden' id='master' value={currentBuyingToRetrieve} ref={masterInputRef} readOnly/>
                    </div>
                    </td>
                    <td>
                    <div className={classes.actions}>
                        <Button variant='custom' onClick={submitHandler}>Hinzufügen</Button>
                    </div>
                    </td>
                </tr>
            </table>
            
        </form>

     </Card>
    );

}

export default NewBuyingItemForm;